import { createMuiTheme } from '@material-ui/core/styles'

const white = '#fff'
const black = '#2e2e2e'
const primaryMain = '#117d89'
const primaryDark = '#546FBF'
const primaryLight = '#79c4ba'
const secondaryMain = '#3F598C'
const secondaryDark = '#BF9765'
const greyLight = '#fafafa'
const errorColor = '#FA3232'

const imp = '!important'

const hpTitle = {
  fontSize: '25px !important',
  lineHeight: '1 !important',
  fontWeight: '700 !important',
  textAlign: 'center',
  '& strong, & b': {
    fontWeight: '700 !important',
  },
  '@media (max-width: 767px)': {
    fontSize: '22px !important',
  },
}

const swiperSlideCSS = {
  width: 'calc(25% - 4px) !important',
  margin: '0 2px !important',
}

const customSelect = {
  borderRadius: `6px ${imp}`,
  padding: `7px 10px ${imp}`,
}

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  themeFont: {
    main: 'Roboto',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    h1: {
      fontSize: 30,
      lineHeight: 1.125,
      fontWeight: 700,
    },
    h2: {
      fontSize: 26,
      lineHeight: 1.2,
      fontWeight: 700,
    },
    h3: {
      fontSize: 24,
      lineHeight: 1.2,
      fontWeight: 700,
    },
    h4: {
      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 700,
    },
    h5: {
      fontSize: 14,
      lineHeight: 1.15,
      fontWeight: 700,
    },
    h6: {
      fontSize: 12,
      lineHeight: 1.15,
      fontWeight: 600,
    },
    body1: {
      fontSize: 14,
      lineHeight: 1.7,
      color: '#333',
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.7,
      color: '#333',
    },
    caption: {
      fontSize: 14,
      lineHeight: 0.8,
      fontWeight: 400,
    },
    button: {
      fontSize: 16,
      fontWeight: 700,
    },
  },
  palette: {
    common: {
      black: black, // Refer to Text color in Figma
      white: white,
    },
    background: {
      default: white,
      paper: white,
    },
    primary: {
      main: primaryMain,
      dark: primaryDark,
      light: primaryLight,
      contrastText: white,
    },
    secondary: {
      main: secondaryMain,
      dark: secondaryDark,
      contrastText: white,
    },
    success: {
      main: '#47E169',
    },
    error: {
      main: errorColor,
    },
    warning: {
      main: '#D54117',
    },
    divider: black,
    primaryTransparent: {
      main: 'rgba(0, 0, 0, .5)',
      light: 'rgba(0, 0, 0, .1)',
    },
    secondaryTransparent: {
      main: 'rgba(118, 118, 118, 1)',
      light: 'rgba(118, 118, 118, .1)',
    },
    ternary: {
      main: '#0082C3',
      light: '#F1FAFF',
    },
    accent: {
      main: '#767677',
    },
  },
  sizes: {
    headerHeight: 75,
    headerHeightTransparent: 150,
    logoWidth: 110,
    logoHeight: 40,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: '#738390',
    greyLight: greyLight,
    greyMedium: '#dddddd',
    greyText: '#333',
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 5,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.MuiButton-root[aria-label="Next slide"], .MuiButton-root[aria-label="Previous slide"], .glider-next, .glider-prev':
          {
            backgroundColor: `${primaryMain} !important`,
            color: `${white} !important`,
          },
        '.banner': {
          '& .MuiTypography-root': {},
          '& .paragraph-class': {
            margin: 0,
          },
          '@media (max-width: 1023.95px)': {},
        },
        '.select': {
          ...customSelect,
        },
        '.header': {
          '@media (max-width: 767px)': {},
          '&__layout': {
            '@media (max-width: 767px)': {},
          },
          '&__logo': {
            '@media (max-width: 767px)': {},
          },
          '&__cta, &__lang': {
            color: `${black} !important`,
            '& a': {
              color: `${black} !important`,
            },
          },
        },
        '.cart-close': {
          '@media (min-width: 1024px)': {
            display: 'block!important',
          },
        },
        '#cart-menu': {
          '& .MuiPaper-root': {
            '@media (min-width: 1024px)': {},
            '@media (min-width: 1024px) and (max-height: 600px) ': {},
          },
        },
        '.basket__btn': {
          minWidth: '0 !important',
          border: `0 !important`,
          '&:before': {
            display: 'none',
          },
          '&.MuiButton-root': {
            minWidth: '0 !important',
            width: '45px!important',
            height: '45px!important',
            '& .MuiButton-label': {
              left: -1,
              '&>div': {
                padding: '0 4px!important',
                border: '0 !important',
                borderRadius: '50% !important',
                color: `${white} !important`,
                background: `${primaryMain} !important`,
                bottom: '-14px !important',
                right: '-13px !important',
              },
              '& .basket__icon': {
                height: '22px !important',
                width: '23px !important',
              },
            },
          },
        },
        '.basket': {
          '&__time, &-product__total, &__discount': {
            color: `${black} !important`,
          },
        },
        '.cart': {
          '&__total': {
            color: `${black} !important`,
          },
        },
        '[data-testid="continue"]': {
          backgroundColor: `${primaryMain} !important`,
          '&:hover, &:focus': {
            backgroundColor: `${primaryDark} !important`,
          },
        },
        '.payment-coupon': {
          '&__grid': {
            '@media (max-width: 421px)': {
              display: 'flex',
              flexDirection: 'column',
            },
          },
        },
        '.hero__home': {
          '& .layout': {
            '@media (min-width: 1024px)': {
              marginTop: '35px!important',
            },
          },
          '& .title': {
            color: `${secondaryMain} !important`,
            '@media (min-width: 1024px)': {
              fontSize: '25px!important',
            },
          },
          '& .tagline': {
            color: `${black} !important`,
            '@media (min-width: 1024px)': {
              fontSize: '14px!important',
            },
          },
        },
        '.date_form': {
          '@media (max-width: 767px)': {
            background: 'none !important',
          },
        },
        '.daterange': {
          borderRadius: '4px !important',
          '&__btn': {
            paddingTop: '1em !important',
            paddingBottom: '1em !important',
            fontSize: '14px !important',
            textTransform: 'uppercase',
          },
          '&__submit': {
            textTransform: 'uppercase',
          },
        },
        '.hero': {
          '&__title': {},
        },
        '.product': {
          '&__container, &__area': {
            maxWidth: '1280px !important',
          },
          '&__left': {
            '& .swiper-container-thumbs': {
              '& .swiper-slide': {
                ...swiperSlideCSS,
              },
            },
          },
          '&__unavailable': {
            color: errorColor,
            marginBottom: '16px! important',
          },
          '&__details': {
            '&--unavailable': {
              display: 'flex',
              flexDirection: 'column-reverse',
            },
          },
        },
        '.packCard': {
          borderRadius: '5px!important',
          '&>.MuiTypography-root:empty': {
            display: 'none',
          },
        },
        '.pack': {
          '&__carousel': {
            '& .swiper-slide': {
              ...swiperSlideCSS,
            },
          },
          '&Sport': {
            '&__foot': {
              '& .MuiButtonBase-root': {
                '@media (max-width: 767px)': {
                  minWidth: '115px !important',
                },
              },
            },
          },
          '&__title': {
            '@media (max-width: 892px)': {
              flexDirection: 'column !important',
              alignItems: 'flex-start !important',
            },
          },
        },
        '.productCard': {
          '&__price': {
            fontWeight: '900 !important',
          },
        },
        '.shop': {
          '&__planner': {
            maxWidth: '1024px !important',
          },
        },
        '[data-testid="nextShipping"]': {
          minWidth: 0,
        },
        '.account': {
          '&-card': {
            paddingTop: '32px !important',
          },
        },
        '.statements': {
          '& .MuiTypography-caption': {
            lineHeight: 1,
          },
        },
        '.faq': {
          '&-content': {
            maxWidth: '1280px !important',
          },
          '&__item': {
            marginBottom: '0 !important',
            '&:first-child': {
              '& .faq__question': {
                borderTop: `1px solid ${primaryMain} !important`,
              },
            },
            '&:last-child': {
              marginBottom: '32px !important',
            },
          },
          '&__question': {
            fontWeight: '400 !important',
            border: '0 !important',
            borderBottom: `1px solid ${primaryMain} !important`,
            marginBottom: '0 !important',
            borderRadius: '0 !important',
            '& .MuiButton-label': {
              '@media (min-width: 1024px)': {
                fontSize: 16,
              },
              '@media (max-width: 1023px)': {
                fontSize: '14px !important',
              },
            },
            '&[aria-expanded=true]': {
              borderTop: '0 !important',
              borderLeft: '0 !important',
              borderRight: '0 !important',
              borderColor: `${primaryMain} !important`,
              background: `${primaryMain} !important`,
              color: `${white} !important`,
              '& .MuiSvgIcon-root': {
                color: `${white} !important`,
              },
            },
          },
          '&__btn': {
            border: '0 !important',
            color: `${black} !important`,
          },
          '&__answer': {
            border: '0 !important',
            borderRadius: '0 !important',
            borderBottom: `1px solid ${primaryMain} !important`,
            marginBottom: '0 !important',
            paddingLeft: '16px !important',
            paddingRight: '16px !important',
          },
        },
        '.testimonial': {
          '&__title': {
            fontSize: '20px !important',
          },
        },
        '.hiw, .testimonial': {
          '&__desc': {},
        },
        '.discover': {
          '&__card, &__shadow': {},
        },
        '.discover-product': {
          '&__content': {},
        },
        '.sizes': {
          '&__btn': {
            overflow: 'inherit !important',
          },
          '&__chip': {
            top: '-10px !important',
            right: '-20px !important',
            padding: '0 !important',
          },
        },
        '.price__amount, .price__duration': {
          color: `${primaryMain} !important`,
        },
        '.section__spacer': {
          padding: '30px 0 !important',
        },
        '.section-menu': {
          margin: '0 !important',
          '& a > .MuiTypography-root': {
            color: `${secondaryMain} !important`,
            fontWeight: '700 !important',
            '@media (max-width: 1023px)': {
              marginRight: '15px !important',
            },
          },
          '& ul': {
            '@media (min-width: 1024px)': {
              display: 'flex',
              justifyContent: 'center',
            },
          },
        },
        '.section-hiw': {
          '&__spacing': {
            paddingTop: '15px !important',
            paddingBottom: '30px !important',
          },
        },
        '.section-infos': {
          '&__content': {
            '& h2': {
              ...hpTitle,
            },
          },
        },
        '.section-hiw, .section-faq, .section-testimonials': {
          '&__title': {
            ...hpTitle,
          },
        },
        '.section-heros': {
          '& .content': {
            '& h2': {
              ...hpTitle,
            },
          },
        },
        '.section-discover': {
          '&__subtitle, &__subtext': {
            ...hpTitle,
          },
        },
        '.section-testimonials': {},
        '.flexContent': {},
        '.footer': {
          '&__bottom': {
            '& a': {
              '&:hover, &:focus': {},
            },
            '& .MuiButtonBase-root': {
              '&:hover, &:focus': {
                backgroundColor: `${primaryDark} !important`,
              },
            },
          },
          '&__social': {
            '&>a': {
              border: `1px solid ${primaryMain}`,
              borderRadius: '48px !important',
              height: '48px !important',
              width: '48px !important',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          },
        },
        '.page__hero': {
          '& .MuiTypography-root': {
            color: `${primaryMain} !important`,
          },
        },
        '.page-content ': {
          '& h2': {
            color: '#177D89',
          },
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: 16,
        lineHeight: 1.25,
        padding: '7.5px 15px',
        borderRadius: 5,
        boxShadow: 'none',
        textTransform: 'none',
      },
      label: {
        position: 'relative',
      },
      sizeSmall: {
        fontSize: 11,
        padding: '7.5px 15px',
      },
      sizeLarge: {
        fontSize: 14,
        padding: 14,
      },
      contained: {
        minWidth: 180,
        '&:hover, &:focus, &:active, &.selected': {},
        '&[disabled]': {},
      },
      containedPrimary: {
        '&:hover, &:focus, &:active, &.selected': {},
      },
      outlinedPrimary: {},
      containedSecondary: {
        '&:hover, &:focus, &:active, &.selected': {},
      },
    },
    MuiPaper: {
      root: {},
      rounded: {
        borderRadius: 0,
      },
    },
    MuiSelect: {
      root: {
        color: black,
      },
      selectMenu: {
        '&:focus': {
          background: white,
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
        fontSize: 15,
        fontWeight: 400,
        color: black,
        backgroundColor: 'transparent',
        height: 25,
        padding: 0,
      },
      label: {
        padding: 0,
      },
      sizeSmall: {
        height: 22,
        fontSize: 12,
        fontWeight: 400,
      },
      labelSmall: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      colorPrimary: {
        color: '#2933E7',
        backgroundColor: 'transparent',
      },
      colorSecondary: {
        color: '#E54C80',
        backgroundColor: 'transparent',
      },
    },
  },
})

export default theme
